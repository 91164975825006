.np-hashrate-chart {
    &__header {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      &-title {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    &__switchers {
      .ant-radio-button-wrapper {
        text-align: center;
      }
    }
  
  
    &__tooltip {
      display: flex;
      flex-direction: column;
      gap: 2px;
      font-size: 0px;
      line-height: 0px;
  
      &-title {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 6px;
      }
  
      &-item {
        font-size: 14px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      }
    }
  }