@import "src/assets/styles";

$margin-bottom-step: -7px;
$padding-x-step: 20px;

.np-stacked-alert {
    &--remove-animation {
        &-top {
            animation-name: removeAnimationTop;
        }

        &-middle {
            animation-name: removeAnimationMiddle;
        }

        &-bottom {
            animation-name: removeAnimationBottom;
        }
    }

    &__wrapper {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        background-color: $main-bg-color; 
        border-radius: $container-border-radius;

        &:has(.np-stacked-alert--middle) {
            bottom: $margin-bottom-step;
            width: calc(100% - $padding-x-step);
        }

        &:has(.np-stacked-alert--bottom) {
            bottom: calc($margin-bottom-step * 2);
            left: 50%;
            width: calc(100% - $padding-x-step * 2);
        }
    }

    &--middle {
        opacity: 0.5;
    }

    &--bottom {
        opacity: 0.2;
    }

    @keyframes removeAnimationTop {
        from {
          opacity: 1;
        }

        to {
            opacity: 0;
            transform: translateX(-50%);
        }
    }

    @keyframes removeAnimationMiddle {
        from {}

        to {
            bottom: 0;
            width: 100%;
        }
    }

    @keyframes removeAnimationBottom {
        from {}

        to {
            bottom: -$margin-bottom-step;
            width: 100%;
            width: calc(100% - $padding-x-step);
        }
    }
}