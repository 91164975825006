@import "src/assets/styles";

.np-filter-select {
    .ant-select-selector {
        border-radius: 40px;
        color: $main-text-color;
    }

    &--with-all {
        .ant-select-selector {
            .ant-select-selection-placeholder {
                color: $main-text-color;
            }
        }    
    }

    &.ant-select {
        &-lg {
            span {
                font-size: 14px;
            }
        }
    }
}