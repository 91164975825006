@import "src/assets/styles/colors";

.np-block-wrapper {
  border-radius: 10px;
  background-color: $main-bg-color-2;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;

  @media (max-width: 700px) {
    padding: 14px 17px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $main-text-color;
    padding: 20px 24px 0;
    
    @media (max-width: 700px) {
      padding: 14px 17px 0px 17px;
    }

    &--large {
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;

      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
  }

  &__header {
    display: flex;
    gap: 14px;
    padding: 18px 24px;
    align-items: center;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba($main-text-color, 0.4);
    padding-top: 8px;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-border-radius {
    border-radius: 0;
  }

  &--stretch {
    height: 100%;
  }
}