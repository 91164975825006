@import "src/assets/styles/index";

$dropdown-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);

.np-user-menu {
    &__dropdown {
        border-radius: $container-border-radius;
        background-color: $menu-bg-color;
        box-shadow: $dropdown-shadow;
        min-width: 250px;

        .ant-dropdown-menu {
            width: 100%;
            box-shadow: none;
            padding: 0 !important;
            border-radius: $container-border-radius;
            display: flex;
            flex-direction: column;
            gap: 16px;
            background-color: $menu-bg-color !important;
        }

        .ant-dropdown-menu .ant-dropdown-menu-item, 
        .ant-dropdown-menu .ant-dropdown-menu-submenu {
            padding: 0 !important;
            border-bottom: none !important;
            border-radius: $container-border-radius !important;

            &:hover {
                background-color: initial !important;
            }

            &-active {
                .ant-dropdown-menu-title-content, span, svg {
                    opacity: 1 !important;
                }
            }
        }

        &-menu {
            width: 100%;
            display: flex;
            justify-content: center;
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            padding: 12px;
            margin-bottom: 12px;
            padding-bottom: 12px !important;
        }

        &-footer {
            border-top: 1px solid $divider-color;
            padding: 28px 24px 12px;
        }
    }
}