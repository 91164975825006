@import "src/assets/styles/colors";
@import "src/assets/styles/constants";

.np-user-layout-header {
  max-width: calc($bodyWidth + ($bodyPadding * 2));
  margin: auto;
  width: 100%;

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $main-bg-color;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: $bodyPadding;
  }

  &__logo {
    margin-top: 4px;
    width: 130px;
    height: 40px;
    cursor: pointer;
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 40px;
  }

  a {
    cursor: pointer;
  }
}