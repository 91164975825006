@import "src/assets/styles";

.np-authentication-form {
    width: 100%;

    &__wrapper {
        width: 100%; 
    }
    
    &__input-block {
        @include input-autocomplete;
        
        &:last-child {
            margin-top: 36px;
            margin-bottom: 0 !important;
        }
    }

    &__links {
        margin-top: 24px;
    }

    .ant-form-item {
        margin-bottom: 24px;
    }

    & #auth_captchaV2 {
        & > div > div {
            margin: auto;
        }
    }
}