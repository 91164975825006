@import "src/assets/styles";

.np-public-layout-auth-button {
    display: flex;
    align-items: center;

    &-login {
        background-color: $white !important;
        font-size: 20px !important;
        padding: 8px 32px !important;
        height: 44px !important;

        @media (max-width: 600px) {
            font-size: 16px !important;
            padding: 8px 16px !important;
        }

        span {
            color: $black !important;
            font-weight: 400;
            transition: color 0.2s;
        }

        &:hover {
            opacity: 1 !important;
            
            span {
                color: $primary !important;
            }
    
        }
    }

    &-registration {
        .ant-btn {
            font-size: 20px !important;
            padding: 8px 32px !important;
            height: 44px !important;
            background-color: $blue-medium;
            margin-left: 24px;
        }
            
        @media (max-width: 800px) {
            .ant-btn {
                margin-left: 8px;   
            }
        }

        @media (max-width: 740px) {
            display: none !important;
        }

        span {
            font-weight: 400;
        }
    }

    @keyframes smooth-appear {
        from {
            opacity: 0;
            display: none;
            width: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            display: block;
            width: auto;
            transform: translateX(0%);
        }
    }

    @keyframes smooth-hide {
        from {
            opacity: 1;
            display: block;
            width: auto;
            transform: translateX(0%);
        }

        to {
            opacity: 0;
            display: none;
            width: 0;
            transform: translateX(100%);
        }
    }

      .show-animation {
        opacity: 1;
        display: block;
        width: auto;
        transform: translateX(0%);
        animation: smooth-appear 0.2s ease-in forwards;

        @media (max-width: 740px) {
            display: none; 
        }
      }

      .hide-animation {
        opacity: 0;
        display: none;
        transform: translateX(100%);
        width: 0;
        animation: smooth-hide 0.2s ease-out forwards;

        @media (max-width: 740px) {
            display: none; 
        }
      }
}