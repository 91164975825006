@import "src/assets/styles";

.np-label-with-tooltip {
    display: flex;
    align-items: center;
    gap: 5px;

    &__icon {
        fill: $spanish-gray;

        path {
            fill: inherit;
        }
    }
}