@import "src/assets/styles";

.np-table-with-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
  
    &__actions {
      display: flex;
      align-items: center;
      padding: 0 24px;
      gap: 16px;

      @media (max-width: 700px) {
        padding: 0 18px;
      }
    }
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 24px 0;

      @media (max-width: 700px) {
        padding: 12px 18px 0;
      }

      &-desc {
        color: $default-desc-color-2;
      }

      &-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 700px) {
          flex-direction: column;
          align-items: baseline;
          gap: 14px;
        }
        
        .np-block-wrapper__title {
          align-self: baseline;

          @media (max-width: 700px) {
            font-size: 16px;
          }
        }
      }

      &-title {
        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;
          flex-wrap: wrap;

          @media (max-width: 700px) {
            font-size: 14px;
            width: 100%;
          }
        }
      }
    }

    .ant-table-header {
      border-radius: 0 !important;
    }

    .np-block-wrapper__description {
        padding-top: 0;

        @media (max-width: 700px) {
          font-size: 12px;
        }
    }
  }