@import "src/assets/styles/index";

.np-disable-2fa-form {
  &__body {
    display: flex;
    flex-direction: column;
  }

  &__totp {
    @include input-autocomplete;
    
    margin-bottom: 0;
  }

  &__link {
    width: fit-content;
    margin-top: 2px;
    align-self: flex-end;

    span {
        white-space: unset;
        text-align: end;
    }
  }

  &__footer {
    margin-top: 36px;
    margin-bottom: 0;
  }

  .ant-modal-footer {
    margin: 0;
  }
}