@import "src/assets/styles";

.np-sidebar {
    background-color: $main-bg-color-2;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        padding: 20px 24px;
        border-bottom: 1px solid $divider-color;
    }

    &__body {
        padding: 12px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &--empty {
            height: auto !important;
            padding: 0;
        }
    }

    &__menu-footer {
        padding: 20px 24px;
        border-top: 1px solid $divider-color;

        &--empty {
            border-top: none;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px 24px;

        &-buttons {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .ant-menu {
        width: inherit !important;
        border-inline-end: none !important;
        background-color: $main-bg-color-2;
        
        .ant-menu-item, .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 2px 0;
        height: auto;
        padding: 8px 12px !important;
        color: $main-text-color;
        width: 100%;

        .ant-menu-title-content {
            margin-left: 0;
        }
        
        span {
            line-height: 24px;
            font-size: 16px;
        }
        }

        .ant-menu-submenu {
        .ant-menu-title-content {
            margin-right: 20px;
        }
        }

        .ant-menu-sub {
        background-color: $main-bg-color-2 !important;

        .ant-menu-item {
            margin-left: 32px;
            width: auto;

            .ant-menu-title-content {
            font-weight: 400;
            margin-right: 0 !important;
            }
        }
        }

        .ant-menu-item {
        &-selected {
            background-color: $primary-10;

            .ant-menu-title-content {
            color: $main-text-color;
            }
        }
        }

        .ant-menu-item-icon {
        font-size: 24px !important;
        width: 24px;
        height: 24px;

        svg {
            color: $primary;
            fill: $primary;

            path {
            color: inherit;
            fill: inherit;
            }
        }
        }
    }
}