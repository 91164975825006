@import "src/assets/styles";

.np-code-input {
    display: flex !important;
    align-items: center;
    gap: 12px;

    input {
        background-color: transparent;
        outline: none;
        color: $main-text-color;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 24px;
        border: none;
        border-bottom: 2px solid $radio-border-color;

        &[value]:not([value=""]) {
            border-bottom: 2px solid $primary;
        }

        &[data-valid=false] {
            border-bottom: 2px solid $error-border-color !important;
        }

        &:disabled {
            background-color: transparent !important;
            color: $default-desc-color-2 !important;
            border-color: $default-desc-color-2 !important;
        }
    }
}