@import "src/assets/styles";

.np-list-block {
    background-color: $main-bg-color-8;
    border: 1px solid $radio-border-color;
    border-radius: 16px;
    position: relative;

    &__header {
        padding: 12px 16px;
        border-bottom: 1px solid $radio-border-color;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
    }

    &__body {
        padding: 12px 16px;
        overflow: auto;

        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 10px;
        } 
    }

    &__overlay {
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: inherit;
        background-color: $overlay-color;
    }

    &--error {
        border-color: $error-border-color;
    }
}