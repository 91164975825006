@import "src/assets/styles/index";

.np-table-list {
    border-radius: $container-border-radius;

    .ant-descriptions-view, table, tbody {
        border-radius: inherit;

        & > tr:first-child {
            border-radius: $container-border-radius $container-border-radius 0 0;

            & > th {
                border-radius: $container-border-radius 0 0 0;
            }

            & > td {
                border-radius: 0 $container-border-radius 0 0;
            }
        }

        & > tr:last-child {
            border-radius: 0 0 $container-border-radius $container-border-radius;

            & > th {
                border-radius: 0 0 0 $container-border-radius;
            }

            & > td {
                border-radius: 0 0 $container-border-radius 0;
            }
        }
    }

    &--vertical {
        th.ant-descriptions-item-label:first-child {
            padding: 0 43px 14px 0 !important;
            text-align: start;
        }

        th.ant-descriptions-item-label {
            padding: 0 43px 14px 43px !important;
            text-align: center;

            span {
                white-space: nowrap;
            }
        }

        
        tr:not(:first-child) th.ant-descriptions-item-label {
            padding-top: 14px !important;
        }

        td.ant-descriptions-item-content:first-child {
            padding: 14px 43px 14px 0 !important;
            text-align: center;
        }

        td.ant-descriptions-item-content {
            padding: 14px 43px !important;
            text-align: center;
        }
    }

    th.np-table-list--align-top {
        vertical-align: top;

    }

    .ant-descriptions-header {
        margin-bottom: 36px;
    }

    .ant-descriptions-view {
        border: none !important;
    }

    .ant-descriptions-title {
        font-size: 28px;
        font-weight: 800;
    }

    th.ant-descriptions-item-label {
        background-color: $table-header-color !important;
        padding: 8px 16px !important;
        max-width: 150px;

        > span {
            font-size: 14px;
            font-weight: 500;
            color: $main-text-color;
        }
    }

    td.ant-descriptions-item-content {
        background-color: $main-bg-color-2 !important;
        padding: 8px 16px !important;

        > span {
            font-size: 14px;
            color: $main-text-color;
        }
    }

    &__wrapper {
        border-radius: $container-border-radius;
        position: relative;
    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $main-bg-color;
        opacity: 0.7;
        z-index: 1;
    }

    &__spin {
        position: absolute;
        transform: translateX(-50%);
        transform: translateY(50%);
        top: 50%;
        left: 50%;
    }

    &__title-wrapper {
        display: flex;
        gap: 20px;
        align-items: center;
    }
}