@import "src/assets/styles";

$full-screen-padding: 8px;

.np-base-modal {
    .ant-modal-header {
        margin-bottom: 22px;
        background-color: inherit;
    }

    .ant-modal-content {
        background-color: $main-bg-color-2;
    }

    .ant-modal-body {
       display: flex;
       flex-direction: column;
       gap: 20px;
    }

    .ant-modal-title {
        font-size: 20px;
        color: $main-text-color;
        padding-right: 32px;
    }

    .ant-modal-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
    }

    .ant-modal-close-icon {
        width: 100%;
        height: 32px;
        font-size: 24px;

        svg {
            fill: $main-text-color;
        }
    }

    .ant-modal-footer {
        margin: 0;
      }

      &--full-screen {
        width: 100% !important;
        height: calc(100% - 2 * $full-screen-padding) !important;
        padding: $full-screen-padding 0 !important;

        .ant-modal-content {
            height: 100% !important;
        }
      }
}