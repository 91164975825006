@import "src/assets/styles/colors";

.np-page-loader {
    &__overlay {
        top: 0;
        z-index: 1000;
        position: fixed;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-bg-color;
    }
}