@import "src/assets/styles";

.np-public-layout-logo {
    width: 200px;
    height: 60px;
    cursor: pointer;

    @media (max-width: 1000px) {
        width: 155px;
        height: 47px;

        svg {
            width: 155px !important;
            height: 47px !important;
        }
    }

    @media (max-width: 600px) {
        width: 130px;
        height: 40px;

        svg {
            width: 130px !important;
            height: 40px !important; 
        }
    }

    svg {
        width: 200px;
        height: 60px;
    }
}