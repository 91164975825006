@import "src/assets/styles";

.np-mining-accounts-table {
    &__hidden {
        color: $spanish-gray;
    }

    .np-copy-button {
        margin-left: 4px;
    }

    &__link {
        padding: 0;
        height: auto !important;
        line-height: 0;
        color: $primary;
        font-weight: 500;
        cursor: pointer;
        transition: 0.15s all;

        &:hover {
            opacity: 0.6;
        }

        span {
            word-break: break-all;
        }
    }
  
    .ant-table-header {
        border-radius: 0 !important;
    }

    .np-table-with-header__actions {
        flex-wrap: wrap;

        @media (max-width: 700px) {
          & > button {
                flex: 1;
                font-size: 12px;
                padding: 6px 13px;
            }
        }
        
        @media (max-width: 375px) {
            flex-direction: column;

            & > button {
                width: 100%;
            }
          }
    }

    .np-table-with-header__header-title-wrapper {
        flex-wrap: wrap;
    }
}