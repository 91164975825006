@import "src/assets/styles/index";

.np-accordion-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &--divider {
        gap: 0 !important;

        .ant-divider {
            margin: 24px 0 !important;
            background-color: $spanish-gray;
        }
    }
}