@import "src/assets/styles/colors";
@import 'src/assets/styles/constants';

.np-footer {
  padding-top: 63px;
  padding-bottom: 63px;
  display: flex;
  justify-content: space-between;
  max-width: $bodyWidth;
  width: 100%;
  box-sizing: border-box;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  &__about {
    display: flex;
    flex-direction: column;
    gap: 22px;

    > p {
      max-width: 270px;
    }

    > ul {
      display: flex;
      gap: 13px;

      > li {
        display: block;
        width: 34px;
        height: 34px;
        background-color: $primary;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }

  &__map {
    display: flex;
    gap: 110px;
    justify-content: space-between;
  }

  &-map {
    &__list {
      display: flex;
      gap: 16px;
      flex-direction: column;

      > h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
      }

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        cursor: pointer;
        color: $footer-link-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .np-footer {
    &__map  {
      gap: 24px;
    }
  }
}

@media (max-width: 800px) {
  .np-footer {
    &__map  {
      display: flex;
      text-align: end;
      flex-direction: column;
      gap: 36px;
    }
  }
}

@media (max-width: 500px) {
  .np-footer {
    flex-direction: column;
    gap: 28px;

    &__map  {
      text-align: start;
    }
  }
}