@import "src/assets/styles";

.np-accounts-summary {
    background-color: $main-bg-color-2;
    border-radius: $container-border-radius;
    padding: 8px 12px;
    gap: 8px;
    display: flex;
    align-items: center;

    &__header {
        padding-right: 8px;
        border-right: 1px solid $radio-border-color;

        &-title {
          font-size: 12px;
          line-height: 24px;
          font-weight: 500;  
        }
    }

    &__body {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 2px;

        &-item {
            display: flex;
            align-items: center;
            gap: 3px;

            &-title {
                font-size: 12px;
                color: $spanish-gray;
                font-weight: 500;
            }

            &-value {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}