@import "src/assets/styles";

.np-input {
    input,
    &.ant-input, 
    &.ant-input-affix-wrapper {
        border-radius: 40px;

        &:not(.ant-input-affix-wrapper-status-error, .ant-input-status-error) {
          &:hover, &:focus, &:active, &:focus-within {
            &:not(:disabled) {
                border-color: $primary !important;
            }
          }
        }
      
        .ant-input-suffix {
          span {
            color: $main-text-color !important;
            width: 13px;
            height: 13px;
          }
        }
      }
      
      &__filled {
        input,
        &.ant-input, 
        &.ant-input-affix-wrapper {
            border-color: $input-no-border-color;
            background-color: $main-bg-color-8 !important;

            &:disabled {
              border-color: $input-no-border-color;
              background-color: $main-bg-color-8 !important;
            }
        }
      }

      &__bordered {
        input,
        &.ant-input, 
        &.ant-input-affix-wrapper {
            border-color: $input-border-color;
            background-color: $main-bg-color-8 !important;

            &:disabled {
              border-color: $input-border-color;
              background-color: $main-bg-color-8 !important;
            }
        }
      }

      &__light {
        padding: 8px 16px;
        height: 44px;

        input,
        &.ant-input, 
        &.ant-input-affix-wrapper {
            border-color: $input-border-color;
            background-color: $white !important;

            &:disabled {
              border-color: $input-border-color;
              background-color: $white !important;
            }
        }
      }

      &-textarea {
        border-radius: 18px !important;
        height: auto;
    }
}