@import "src/assets/styles/index";

.np-desktop-navigation-menu {
    flex: auto !important;
    min-width: 0 !important;
    justify-content: center;
    background: none;
    border-bottom: 0;
    max-height: 40px;
    min-width: 46px !important;
    
    .ant-menu-title-content {
        color: $main-text-color;
        font-size: 16px;
    }
}