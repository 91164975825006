@import "src/assets/styles/colors";
@import "src/assets/styles/constants";

.np-user-layout-header {
  &__icon {
    fill: $extradark-gray;
    width: 14px;
    height: 14px;

    @media (max-width: 500px) {
        width: 11px;
        height: 11px;
    }

    path {
      fill: inherit
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: $gradient-gold-3;
      border-radius: 50%;

        @media (max-width: 500px) {
            width: 16px;
            height: 16px;
        }
    }
  }
}