@import "src/assets/styles";

$section-gap-desktop: 140px;
$section-gap-tablet: 100px;
$section-gap-mobile: 80px;
$section-gap-mobile-sm: 64px;

.np-public-layout {
    * {
        font-family: $thirdFont;
    }

    min-height: 100vh;
    background-color: $sky-extralight;
    color: $black;
    display: flex;
    flex-direction: column;
    gap: $section-gap-desktop;
    position: relative;

    span, p {
        color: $black;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: $section-gap-desktop;

        &-body {
            flex: 1;
        }
    }

    @media (max-width: 1440px) {
        gap: $section-gap-tablet;

        &__content {
            gap: $section-gap-tablet;
        }
    }

    @media (max-width: 800px) {
        gap: $section-gap-mobile;

        &__content {
            gap: $section-gap-mobile;
        }
    }

    @media (max-width: 500px) {
        gap: $section-gap-mobile-sm;

        &__content {
            gap: $section-gap-mobile-sm;
        }
    }
}