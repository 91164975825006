@import "src/assets/styles";

.np-referral-info {
    gap: 28px;

    @media (max-width: 700px) {
        gap: 12px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }
}