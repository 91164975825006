@import "src/assets/styles";

.np-reset-process-identif-verify {
    &__files {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .ant-btn {
            font-size: 14px;
        }
    }
}