@import "src/assets/styles";

.np-reset-process-result {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-result .ant-result-icon span {
        font-size: 72px !important;
    }

    .ant-result-title {
        font-size: 28px; 
        margin-bottom: 16px;
    }

    .ant-result-subtitle {
        font-size: 16px;
    }

    &__subtitle {
            font-size: inherit;

        span {
            font-size: inherit;
        }

        &--accent {
            color: $accent-text-color;
            font-weight: 500;
            margin-left: 6px;
        }
    }

    &__button, a {
        width: 100%;
        max-width: 360px;

        span {
            font-size: 14px;
        }
    }

    .ant-result {
        padding: 10px 0;
    }
}