@import "src/assets/styles";

.np-reset-process-security-verify {
    &__form {
        display: flex;
        flex-direction: column;

        &-code {
            margin-bottom: 8px;

            @media (max-width: 550px) {          
                .np-code-input {
                    input {
                        width: 30px;
                        height: 30px;
                        font-size: 16px;
                    }
                }
            }
        }

        &-refetch-timer {
            align-self: flex-end;
            margin-bottom: 24px;
        }

        .ant-form-item {
            .ant-btn {
                font-size: 14px;
            }
            
            &:last-child {
                margin-bottom: 0;
                margin-top: 12px;
            }
        }
        
        &-captcha {
            & .ant-form-item-control-input-content > div {
                margin: auto;
            }
        }
    }
}