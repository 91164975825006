@import "src/assets/styles/index";

.np-boolean-tag {
    padding: 4px 16px;
    border-radius: $container-border-radius;
    font-weight: 400;
    text-align: center;
    width: fit-content;

    &--on {
        background-color: $primary;
        color: $main-icon-color;
    }

    &--off {
        color: $main-icon-color;
        background-color: $spanish-gray;
        opacity: 0.7;
    }
}