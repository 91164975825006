@import "src/assets/styles/index";

.np-password-validation-input {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__form-item {
    .ant-form-item-explain {
      display: none !important;
    }
  }

  @include input-autocomplete;
}

