@import "src/assets/styles";

.np-table {
    &__sort-title {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            stroke: $primary;
            fill: $primary;
            flex: none;
            
            path {
                stroke: $primary; 
                fill: $primary; 
            }
        }

        &--ghost {
            stroke: $main-text-color !important;
            fill: $main-text-color !important;

            path {
                stroke: $main-text-color !important; 
                fill: $main-text-color !important; 
            }
        }

        &--center {
            justify-content: center;
        }

        &--left {
            justify-content: flex-start;
        }

        &--right {
            justify-content: flex-end;
        }
    }
}