@import "src/assets/styles";

.np-user-menu-footer {
    margin-bottom: 12px;

    &__logout {
        display: flex;
        align-items: center;
        padding: 0;

        span {
            color: $main-text-color;
            font-weight: 400;
            font-size: 16px;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}