@import "src/assets/styles";

.np-chat-bot-widget {
    & > div {
        display: block !important;
    }

    .rw-conversation-container {
        .rw-header {
            background-color: $primary;
            box-shadow: 0 2px 10px 1px $black-25;
            z-index: 1;

            .rw-title {
                color: $main-icon-color;
            }
        }

        .rw-messages-container {
            background-color: $main-bg-color-2;

            .rw-message {
                .rw-client {
                    background-color: $primary;
                    color: $main-icon-color;
                    font-weight: 500;
                }

                .rw-response {
                    background-color: $main-bg-color-6;
                    color: $main-text-color;
                }
            }
        }

        .rw-sender {
            background-color: $main-bg-color-6;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 0 2px 10px 1px $black-25;
            position: relative;
            z-index: 1;

            .rw-new-message {
                background-color: inherit;
                color: $main-text-color;
            }

            .rw-send {
                cursor: pointer;
                transition: all 0.2s;
                border-radius: 50%;
                width: 34px;
                height: 34px;
    
                background-color: inherit;
    
                &:hover {
                    opacity: 0.7;
                }
    
                svg {
                    width: 20px;
                    height: 20px;
                    fill: $primary;
    
                    path {
                        fill: inherit;
                    }
                }
            }
        }

        .rw-reply {
            background-color: $primary;
        }
    }

    .rw-widget-container {
        .rw-launcher {
            background-color: $primary; 
            box-shadow: 0 2px 10px 1px $black-25;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                filter: brightness(0.9);
            }
        }
    }
}