@import "src/assets/styles";

.np-mining-notifications-table {
  &__label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    
    @media (max-width: 700px) {
        flex-direction: column;
        align-items: baseline;
        width: fit-content;
        margin: 0 auto;
    }

    .ant-tag {
      font-weight: 500;
      font-size: 14px;
      padding: 2px 10px;
      margin-inline-end: 0;

      &.ant-tag-has-color {
        color: $main-icon-color;
      }

      @media (max-width: 700px) {
        font-size: 12px;
        padding: 0 6px;
      }
    }

    .ant-typography {
        text-align: start;
        font-weight: 500;
    }
  }

  &__text {
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      @media (max-width: 700px) {
          gap: 8px;
      }

      .ant-typography {
        word-break: normal;

        @media (max-width: 700px) {
          font-size: 12px;
        }
      }
    }

    &--no-wrap {
      white-space: nowrap;
      font-weight: 500;
    }

    &--primary {
      color: $primary;
    }
  }
}