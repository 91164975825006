.np-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 60px;
  min-width: 64px;

  &--inline {
    &.ant-spin.ant-spin-spinning {
      position: relative;
      line-height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .ant-spin-dot {
        position: relative;
        margin: 0;
        padding: 0;
        top: 0;
        left: 0;
        inset-inline-start: 0;
        margin-top: 0 !important;
      }

      .ant-spin-text {
        text-align: left;
        position: relative;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        width: fit-content;
      }
    }
  }

  &--no-margin {
    min-height: 32px;
  }
}