@import "src/assets/styles";

.np-radio-group {
    &.ant-radio-group {
        .ant-radio-button-wrapper {
            background-color: $main-bg-color-2;
    
        &-checked {
            &.ant-radio-button-wrapper-disabled {
                background-color: $overlay-color;
    
                span {
                    color: $spanish-gray !important;
                }
            }
        }
    
            &-disabled {
                opacity: 0.8;
            }
        }
    
        &-small {
            .ant-radio-button-wrapper {
                padding: 1px 10px !important;
                height: 25px;
        
                span {
                    text-align: center;
                }
            }
        }
    }
}