.np-profit-chart {
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__title {
    line-height: 32px;
  }
}