@import "src/assets/styles";

.np-copy-button {
    width: 16px;
    height: 16px;
    fill: $primary;
    color: $primary;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
        opacity: 0.6;
    }
}