@import "src/assets/styles/index";

.np-sidebar-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    height: 100%;
    width: 100%;

    &__menu {
      width: $sidebar-desktop-width;
      position: sticky;
      top: 0;

      @media (max-width: 900px) {
        display: none;
      }
    }

    &__content {
      width: 100%;
      max-width: calc(100% - $sidebar-desktop-width);
      overflow-y: auto;

      @media (max-width: 900px) {
        max-width: none;
      }
    }
  
    .ant-menu.ant-menu-root {
      background: transparent;
      border: 0;
    }
  
    .ant-menu-item-selected {
      background-color: $primary-20;
    }
  }