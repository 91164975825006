@import "src/assets/styles";

.np-reset-process-confirmation {
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-form-item {
            margin-bottom: 30px;

            .ant-btn {
                font-size: 14px;
            }
        }

        .ant-checkbox-group {
            flex-direction: column;
            row-gap: 12px;
        }

        .ant-form-item-explain-error {
            padding-left: 24px;
            padding-top: 6px;
        }
    }
}