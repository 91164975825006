@import "src/assets/styles";

.np-files-dropzone {
    background-color: $main-bg-color;
    border: 2px dashed $radio-border-color;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    transition: border 0.2s;
    position: relative;

    &__preview {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-icon {
            color: $primary;
            width: 50px;
            height: 50px;
            font-size: 70px;

            &--disabled {
                color: $primary-button-text-disabled-color;
            }
        }

        &-text {
            &-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .ant-typography {
                    color: $default-desc-color;
                    font-weight: 500;
                }

                & > div {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }
        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
        background-color: $main-bg-color;
        opacity: 0.7;
    }

    &--focused {
        border-color: $primary;
    }

    &--drag-accept {
        border-color: $primary;
    }

    &--drag-reject {
        border-color: $error-border-color;
    }
}