@import "src/assets/styles";

.np-alert-modal {
    &--info {
        .np-base-modal-title {
            color: $primary !important;
        }
    }

    &--success {
        .np-base-modal-title {
            color: $green-medium-2 !important;
        }
    }

    &--error {
        .np-base-modal-title {
            color: $error-red !important;
        }
    }

    &--warning {
        .np-base-modal-title {
            color: $orange-medium !important;
        }
    }
}