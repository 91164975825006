@import "src/assets/styles";

.np-desktop-accounts-summary {
    &__workers {
        display: flex;
        align-items: center;
        gap: 8px;

        &-item {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &-icon {
            font-size: 16px;
        }

        &-value {
            font-size: 12px;
            font-weight: 500;
            margin-top: 2px;
        }
    }
}