@import "src/assets/styles/index";

.np-button {
    border-radius: 40px;
    font-weight: 500;
    font-size: 12px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.ant-btn-link {
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        height: auto;

        &:disabled {
                color: $primary-button-text-disabled-color; 
            }
    }
    
    &.ant-btn-sm {
        font-size: 12px;
        border-radius: 40px;
    }
    
    &.ant-btn-lg {
        font-size: 14px;
        line-height: 14px;
        border-radius: 40px;
    }

    &__primary {
        &--primary {
            &.ant-btn-primary {
                color: $primary-button-text-color;
    
                &:hover:not(:disabled) {
                    opacity: 0.6;
                    color: $primary-button-text-color !important;
                }
            }

            &:disabled {
                background-color: $primary;
                box-shadow: 0 2px 0 rgba(25, 225, 255, 0.1);
                opacity: 0.5;
                border: 1px solid transparent;
              }
        }

        &--primary-light {
            &.ant-btn-primary, span {
                color: $white !important;
                background-color: $blue-medium !important;
                font-weight: 400;
    
                &:hover:not(:disabled) {
                    opacity: 0.6;
                    color: $white !important;
                }
            }

            &:disabled {
                background-color: $blue-medium;
                box-shadow: 0 2px 0 rgba(25, 225, 255, 0.1);
                opacity: 0.5;
                border: 1px solid transparent;
              }
        }

        &--red {
            &.ant-btn-primary {
                background-color: $red-medium;
                color: $primary-button-text-color;
    
                &:hover:not(:disabled) {
                    opacity: 0.6;
                    background-color: $red-medium;
                    color: $primary-button-text-color !important;
                }
            }

            &:disabled {
                background-color: $red-medium;
                box-shadow: 0 2px 0 rgba(25, 225, 255, 0.1);
                opacity: 0.5;
                border: 1px solid transparent;
              }
        }
    }

    &__default {
        &--primary {
            &.ant-btn-default {
                border-color: $primary !important;
                color: $primary  !important;
                background-color: transparent;
                box-shadow: none;
            }

            &:disabled {
                opacity: 0.4;
            }

            &:hover:not(:disabled) {
                opacity: 0.6;
                color: $primary;
            }
        }

        &--red {
            &.ant-btn-default {
                border-color: $red-medium !important;
                color: $red-medium  !important;
                background-color: transparent;
                box-shadow: none;
            }

            &:disabled {
                color: $spanish-gray !important;
                border-color: $spanish-gray !important;
                opacity: 0.4;
            }

            &:hover:not(:disabled) {
                opacity: 0.6;
                color: $red-medium;
            }
        }

        &--default {
            &.ant-btn-default {
                border-color: $default-button-border-color !important;
                color: $default-button-text-color !important;
                background-color: transparent;
                box-shadow: none;
            }

            &:disabled {
                opacity: 0.4;
            }

            &:hover:not(:disabled) {
                color: $primary !important;
                border-color: $primary !important;
            }
        }
    }
}