@import "src/assets/styles";

.np-editable-block-value {
  &__loading {
    min-height: 0;
  }

  &__actions {
      display: flex;
      align-items: center;
      gap: 16px;

      &-label {
        flex: 1;
        width: 100%;
        font-weight: 500;
        margin: auto 0;
        display: flex;
        align-items: center;

        &--ellipsis {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      @media (max-width: 700px) {
        flex: 1;
        gap: 12px;
        justify-content: space-between;

        &-label {
          flex: auto;
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        @media (max-width: 700px) {
          gap: 12px;
        }
      }
    }
}