$satoshi: 'Satoshi';
$raleway: 'Raleway';
$roboto: 'Roboto';
$roboto-mono: 'Roboto Mono';
$fira-mono: 'Fira Mono'; //monospaced
$nunito: 'Nunito';
$sf-pro: 'SF Pro';

$default: sans-serif;

$mainFont: $raleway, $default;
$secondFont: $roboto, $satoshi, $nunito, $default;
$monoFont: $roboto, $fira-mono, $default;
$thirdFont: $sf-pro, $default;