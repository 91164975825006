@import "src/assets/styles";

.np-payment-settings-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0 24px;

    @media (max-width: 500px) {
        flex-direction: column;

        &-item {
            width: 100% !important;
        }
    }

    @media (max-width: 700px) {
        padding: 0 17px;
    }
}