@import "src/assets/styles";

.np-reset-process-identif-verify {
    &__requirements {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        ol {
            padding-inline-start: 20px;
            
            li {
                font-size: 14px;
                line-height: 1.57;
                color: $default-desc-color-2;
            }
        }
    }
}