// Default Colors

$primary: var(--PRIMARY_COLOR);
$primary-5: var(--PRIMARY_COLOR_5);
$primary-10: var(--PRIMARY_COLOR_10);
$primary-20: var(--PRIMARY_COLOR_20);
$primary-30: var(--BATTERY_CHARGED_BLUE_30);
$primary-50: var(--BATTERY_CHARGED_BLUE_50);
$primary-70: var(--BATTERY_CHARGED_BLUE_70);
$maximum-blue: var(--MAXIMUM_BLUE);
$diamond: var(--DIAMOND);
$sky-extralight: var(--SKY_EXTRALIGHT);
$blue-medium: var(--BLUE_MEDIUM);

$white: var(--WHITE);
$white-25: var(--WHITE_25);

$black: var(--BLACK);
$black-3: var(--BLACK_3);
$black-5: var(--BLACK_5);
$black-40: var(--BLACK_40);
$black-25: var(--BLACK_25);

$extradark-gray: var(--EXTRADARK_GRAY);
$spanish-gray: var(--SPANISH_GRAY);
$arsenic: var(--ARSENIC);
$vivid-lime-green: var(--VIVID_LIME_GREEN);
$eerie-black-dark: var(--EERIE_BLACK_DARK);
$light-silver: var(--LIGHT_SILVER);
$light-silver-20: var(--LIGHT_SILVER_20);
$medium-gray: var(--MEDIUM_GRAY);

$red-medium: var(--RED_MEDIUM);

$orange-medium: var(--ORANGE_MEDIUM);

$maastricht-blue: var(--MAASTRICHT_BLUE);
$crayola-blue: var(--CRAYOLA_BLUE);

$tussock: var(--TUSSOCK);
$lava: var(--LAVA);

$error-red: var(--ERROR_RED);

$green-medium-2: var(--GREEN_MEDIUM_2);

// Gradients

$gradient-blue: linear-gradient(137deg, $primary 0%, #1DAAEA 56.77%, $primary 100%);
$gradient-blue-2: linear-gradient(90deg, $primary 0%, #0A86BF 100%);
$gradient-gold: linear-gradient(90deg, #ad8528 0%, #eadb68 54.65%, $tussock 90.64%);
$gradient-gold-1: linear-gradient(90deg, #ad8528 0%, #eadb68 25.65%, $tussock 50.64%);
$gradient-gold-2: linear-gradient(90deg, #ad8528 0%, #FFF9A3 25.65%, $tussock 50.64%);
$gradient-gold-3: linear-gradient(90deg, #FFF9A3 10.42%, #ad8528 98.96%);

// Components Colors

$main-bg-color: var(--MAIN_BG_COLOR);
$main-bg-color-2: var(--MAIN_BG_COLOR_2);
$main-bg-color-3: var(--MAIN_BG_COLOR_3);
$main-bg-color-4: var(--MAIN_BG_COLOR_4);
$main-bg-color-5: var(--MAIN_BG_COLOR_5);
$main-bg-color-6: var(--MAIN_BG_COLOR_6);
$main-bg-color-7: var(--MAIN_BG_COLOR_7);
$main-bg-color-8: var(--MAIN_BG_COLOR_8);
$main-bg-color-9: var(--MAIN_BG_COLOR_9);
$main-bg-color-10: var(--MAIN_BG_COLOR_10);
$main-bg-color-11: var(--MAIN_BG_COLOR_11);
$main-bg-color-12: var(--MAIN_BG_COLOR_12);

$blue-bg-color: var(--BLUE_BG_COLOR);
$blue-light-bg-color: var(--BLUE_LIGHT_BG_COLOR);
$blue-inverse-bg-color: var(--BLUE_INVERSE_BG_COLOR);

$green-bg-color: var(--GREEN_BG_COLOR);

$menu-bg-color: var(--MENU_BG_COLOR);
$menu-item-bg-color: var(--MENU_ITEM_BG_COLOR);
$menu-item-border-color: var(--MENU_ITEM_BORDER_COLOR);

$primary-desc-color: var(--PRIMARY_DESC_COLOR);
$red-desc-color: var(--RED_DESC_COLOR);
$default-desc-color: var(--DEFAULT_DESC_COLOR);
$default-desc-color-2: var(--DEFAULT_DESC_COLOR_2);
$default-desc-color-3: var(--DEFAULT_DESC_COLOR_3);
$default-desc-color-4: var(--DEFAULT_DESC_COLOR_4);

$main-text-color: var(--MAIN_TEXT_COLOR);
$main-icon-color: var(--MAIN_ICON_COLOR);
$accent-text-color: var(--ACCENT_TEXT_COLOR);

$primary-button-text-color: var(--PRIMARY_BUTTON_TEXT_COLOR);
$primary-button-text-disabled-color: var(--PRIMARY_BUTTON_TEXT_DISABLED_COLOR);
$default-button-text-color: var(--DEFAULT_BUTTON_TEXT_COLOR);
$default-button-border-color: var(--DEFAULT_BUTTON_BORDER_COLOR);

$gold-banner-bg-color: var(--GOLD_BANNER_BG_COLOR);

$primary-banner-bg-color: var(--PRIMARY_BANNER_BG_COLOR);

$accent-banner-bg-color: var(--ACCENT_BANNER_BG_COLOR);
$accent-banner-text-color: var(--ACCENT_BANNER_TEXT_COLOR);

$popover-border-color: var(--POPOVER_BORDER_COLOR);
$banner-border-color: var(--BANNER_BORDER_COLOR);

$footer-link-color: var(--FOOTER_LINK_COLOR);

$radio-border-color: var(--RADIO_BORDER_COLOR);

$checkbox-border-color: var(--CHECKBOX_BORDER_COLOR);

$input-border-color: var(--INPUT_BORDER_COLOR);
$input-no-border-color: var(--INPUT_NO_BORDER_COLOR);

$error-border-color: var(--ERROR_BORDER_COLOR);

$default-tag-bg-color: var(--DEFAULT_TAG_BG_COLOR);

$divider-color: var(--DIVIDER_COLOR);

$overlay-color: var(--OVERLAY_COLOR);
$select-overlay-color: var(--SELECT_OVERLAY_COLOR);

$tab-bg-color: var(--TAB_BG_COLOR);
$tab-text-color: var(--TAB_TEXT_COLOR);

$table-row-color: var(--TABLE_ROW_COLOR);
$table-row-hover-color: var(--TABLE_ROW_HOVER_COLOR);
$table-header-color: var(--TABLE_HEADER_COLOR);
$table-column-disabled-color: var(--TABLE_COLUMN_DISABLED_COLOR);
$table-header-disabled-color: var(--TABLE_HEADER_DISABLED_COLOR);
$table-selected-row-color: var(--TABLE_SELECTED_ROW_COLOR);

$chart-divide-line-color: var(--CHART_DIVIDE_LINE_COLOR);
$chart-divide-line-color-2: var(--CHART_DIVIDE_LINE_COLOR_2);

$scrollbar-track-color: var(--SCROLL_TRACK_COLOR);
$scrollbar-thumn-color: var(--SCROLL_THUMB_COLOR);
$scrollbar-thumn-active-color: var(--SCROLL_THUMB_ACTIVE_COLOR);
$scrollbar-thumn-hover-color: var(--SCROLL_THUMB_HOVER_COLOR);