@import "src/assets/styles";

$sectionPaddingBottom: 84px;

.np-section-layout {
    padding: 0 $bodyPadding $sectionPaddingBottom $bodyPadding;
    max-width: calc($bodyWidth + ($bodyPadding * 2));
    margin: 0 auto;
    flex: 1;
    width: 100%;
}