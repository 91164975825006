@import "src/assets/styles/index";

.np-mobile-navigation-menu {
    &__icon {
        width: 32px;
        height: 32px;

        svg {
            width: 26px;
            height: 26px;
        }
    }

    &__drawer {
        border-radius: 0 16px 16px 0;
        background-color: $main-bg-color-2 !important;
        overflow: initial !important;

        .ant-drawer-body {
            padding: 0;
            overflow: initial;
        }
    }

    &__sidebar-footer {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
    }

    &__close-btn {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
        transition: opacity 0.15s;

        fill: $main-text-color;

        path, rect {
            fill: inherit;
        }

        &:hover {
            opacity: 0.5;
        }
    }
}