@import "src/assets/styles";

.np-card-tabs {
    &--stretch {
        height: 100%;

        .ant-tabs-content {
            height: 100%;

            .ant-tabs-tabpane {
                height: 100%;
            }
        }
    }
}