@import "src/assets/styles";

.np-mining-notifications-table {
  &__footer {
    padding-top: 0;
  }

  &__tooltip {
    &-title {
      display: inline-flex;
      align-items: center;
      gap: 6px;
    }

    path {
      fill: $spanish-gray;
    }
  }

  .ant-table-cell {
    .ant-typography {
      @media (max-width: 700px) {
        font-size: 12px;
      }
    }
  }
}