@import "src/assets/styles";

.np-carousel {
    .slick-arrow {
        width: 16px;
        height: 16px;

        svg {
            font-size: 16px;
            fill: $spanish-gray;
        }
    }

    .slick-list {
        border-radius: $container-border-radius;
    }

    .slick-prev {
        left: -24px;
    }

    .slick-next {
        right: -24px;
    }
}
