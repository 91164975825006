@import "src/assets/styles";

.np-watcher-urls-table {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
        fill: $primary;
        width: 12px;
        height: 12px;
        font-size: 12px;
        cursor: pointer;
    }
  }
  }