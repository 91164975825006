@import "src/assets/styles";

$landing-max-width: 1440px;

.np-public-layout-footer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__left {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    &__right {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 700px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &-text {
            display: flex;
            flex-direction: column;

            @media (max-width: 1000px) {
                max-width: 371px;
            }
        }
    }

    [role="button"] {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;   
        }
    }

    .ant-typography {
        color: $spanish-gray;
        font-size: 16px;
        line-height: 120%;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 24px !important;

        .ant-typography {
            font-size: 14px;
        }
    }
}