@import "src/assets/styles";

.np-refresh-action {
    justify-content: flex-end;
    flex-wrap: wrap-reverse;

    .ant-typography {
        white-space: nowrap;
    }

    &__icon {
        margin-top: 2px;
    }
}