@import "src/assets/styles";

.np-timer-button {
    &__timer {
        line-height: 14px;

        .ant-statistic-content-value {
            font-size: 14px;
            color: $default-desc-color-2;
        }
    }
}