@import "src/assets/styles/index";

.np-sign-up-agreement-modal {
    &__header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0 12px;
        text-align: center;
        margin: 0 auto;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        border-radius: 6px;
        background-color: $main-bg-color-3;
        max-height: 300px;
        overflow: auto;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__text-content {
        display: flex;
        flex-direction: column; 
        gap: 10px; 
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        padding-top: 20px;

        @media (max-width: 500px) {
            flex-direction: column;
        }

        .ant-btn {
            flex: 1;

            @media (max-width: 500px) {
                width: 100%;
                flex: none;
            }
        }

        .ant-btn-default {
            border-color: $primary !important;
            color: $primary !important;
        }
    }
}