@import "src/assets/styles";

.np-reset-process-identify-verify {
    &__form {
       width: 100%; 

       &-button {
            width: 100%;
            max-width: 360px;
            margin: 0 auto;
       }

       .ant-form-item {
            &:last-child {
                margin-bottom: 0;
                margin-top: 36px;
            }
       }

       &-captcha {
            & .ant-form-item-control-input-content > div {
                margin: auto;
            }
        }
    }
}