@import "src/assets/styles";

.np-background-loader {
    bottom: 16px;
    z-index: 1000;
    position: fixed;
    padding: 16px;
    border-radius: $container-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-bg-color-4;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}