@import "src/assets/styles";

.np-search-input {
    width: 215px;
    padding: 8px 16px;
    height: 40px;
    background-color: $main-bg-color-2 !important;

    &.ant-input-affix-wrapper-sm {
      height: 32px;
    }

    input {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-right: 0;
    }

    &:hover {
      .np-search-input__button-reset {
        opacity: 1;
      }
    }

    .ant-input-suffix {
      margin-top: 2px;
      
      svg {
        fill: $radio-border-color;
        transition: all 0.2s;
      }

      &:hover {
        svg {
          fill: $default-desc-color-2;
        }
      }
    }

    &__button {
        padding: 0 !important;
        border: none !important;
        height: auto !important;
  
        &:hover {
          opacity: 0.6 !important;
        }
  
        .anticon {
          font-size: 16px !important;
          width: 16px !important;
          height: 16px !important;
  
            svg {
              fill: $primary !important;
            }
        }
  
        &-reset {
          opacity: 0;
          transition: opacity 0.15s;
  
          .anticon {  
              svg {
                fill: $spanish-gray !important;
              }
          }
        }
      }
}