@import "src/assets/styles";

.np-row-value {
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 700px) {
    font-size: 14px;
  }

  &__loading {
        min-height: 0;
      }
}
