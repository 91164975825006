@import "src/assets/styles";

.np-mobile-accounts-summary {
    &__workers {
        display: flex;
        align-items: center;
        gap: 24px;

        &-item {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &-icon {
            font-size: 24px;

            @media (max-width: 700px) {
                font-size: 18px;
            }
        }

        &-value {
            font-size: 24px;
            font-weight: 400;
            margin-top: 2px;

            @media (max-width: 700px) {
                font-size: 18px;
            }
        }
    }
}