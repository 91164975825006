@import "src/assets/styles";

.np-permissions-popover {
    &__content {
        &-item {
            display: flex;
            align-items: center;
            gap: 5px;
            color: $main-text-color;
    
            span {
                color: $default-desc-color;
            }
        }
    }
    &__title {
        color: $main-text-color;
    }
}