@import "src/assets/styles/index";

.np-status-tag {
    padding: 5px 15px;
    border-radius: $container-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
            width: 16px;
            height: 16px;
            font-size: 16px;
        }
    }

    &__dot {
        height: 10px;
        width: 10px;
        border-radius: 10px;
    }

    &--small {
        padding: 3px 10px;

        svg {
            width: 12px;
            height: 12px;
            font-size: 12px;
        }

        .np-status-tag__dot {
            height: 8px;
            width: 8px;
            border-radius: 8px;
        }

        .ant-typography {
            font-size: 12px;
            font-weight: 500;
        }
    }
}