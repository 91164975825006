@import "src/assets/styles/index";

.np-result {
    padding: 24px 36px 36px;

    @media (max-width: 700px) {
      padding: 12px 16px 16px;
    }
  
    .ant-result-icon span {
      font-size: 100px !important;
  
      @media (max-width: 700px) {
        font-size: 50px !important;
      }
    }
  
    .ant-result-title {
      margin-block: 0;
      margin-bottom: 24px;
      font-size: 48px;
      color: $main-text-color;
  
      @media (max-width: 700px) {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
  
    .ant-result-subtitle {
      font-size: 16px;
      font-weight: 400;
      color: $default-desc-color;
  
      @media (max-width: 700px) {
        font-size: 12px !important;
  
        span {
          font-size: inherit;
        }
      }
    }
  
    &.ant-result-success {
      .anticon-check-circle {
        color: $primary !important;
      }
    }
}