@import "src/assets/styles";

.np-menu {
    cursor: pointer;

    .ant-typography {
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
    }

    span.anticon-down {
        padding-bottom: 0;
        flex: none;
    }

    &--outlined {
        border: 1px solid $spanish-gray;
        padding: 10px 24px;
        border-radius: 50px;
        height: 44px;
        transition: all 0.2s;

        .ant-typography {
            font-size: 20px;
            transition: color 0.2s;
        }

        svg path {
            transition: stroke 0.2s;
        }

        &:hover, &:focus, &:active, &.ant-dropdown-open {
            border-color: $primary;

            .ant-typography {
                color: $primary;
            }

            svg path {
                stroke: $primary;
            }
        }
    }

    &__dropdown {
        .ant-dropdown-menu {
            padding: 8px 20px !important;
            background-color: $main-bg-color-2 !important;
          
            .ant-dropdown-menu-item, .ant-dropdown-menu-submenu {
                padding: 5px 0 !important;
                border-bottom: 1px solid $main-bg-color;
                border-radius: 0 !important;
                background-color: $main-bg-color-2 !important;
                text-align: center;
          
                &:last-child {
                    border-bottom: none;
                }
          
                span, svg {
                    transition: all 0.2s;
                }
          
                &-active {
                    background-color: $main-bg-color-2 !important;
          
                    span, svg {
                        opacity: 0.7;
                    }
                }
          
                .ant-dropdown-menu-submenu-title {
                    padding: 0;
                    background-color: $main-bg-color-2 !important;
                }
            }
          
            .ant-dropdown-menu-submenu {
              padding-right: 30px !important;
            }
          
            .ant-dropdown-menu-sub {
              padding: 0 !important;
              box-shadow: none;
            }
          }
          
          .ant-dropdown-menu-submenu-popup {
            border-radius: 6px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          }

          &--outlined {
            .ant-dropdown-menu-item, .ant-dropdown-menu-submenu {
                font-size: 16px !important;
            }
          }
    }
}