@import "src/assets/styles";

.np-download-app-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto !important;
    background-color: $black;
    border-radius: 10px !important;
    border: 1px solid $spanish-gray;
    padding: 7px 10px 10px 10px;
    gap: 9px;

    &:hover {
        background-color: $eerie-black-dark !important;
    }

    &__logo {
        width: 32px;
        height: 32px;
        transform: translateZ(0);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-title {
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            color: $white;
        }
        
        &-platform {
            font-size: 24px;
            line-height: 24px;
            font-weight: 500;
            color: $white;
        }
    }
}