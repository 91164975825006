@import "src/assets/styles";

.np-editable-block-value {
    &__button {
      min-width: 32px;

      &:not(:disabled) {
        border-color: $primary !important;
        color: $primary !important;
  
        &:hover {
          opacity: 0.8;
        }
      }

      &:disabled {
        color: $default-desc-color-2 !important;
        border-color: $default-desc-color-2 !important;
        opacity: 0.5;
      }
    }
}