@import "src/assets/styles";

.np-wallet-label-tooltip {
    &__list {
        padding-inline-start: 20px;
    }

    .ant-typography {
        color: $white;
    }
}