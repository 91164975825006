$autocomplete-box-shadow: 0 0 0 30px $main-bg-color-8 inset !important;

@mixin input-autocomplete {
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active,
  input:-internal-autofill-selected,
  input:autofill,
  input:autofill:hover,
  input:autofill:focus,
  input:autofill:active,
  input:-moz-autofill,
  input:-moz-autofill:hover,
  input:-moz-autofill:focus,
  input:-moz-autofill:active,
  input:-moz-autofill-preview,
  input:-moz-autofill-preview:hover,
  input:-moz-autofill-preview:focus,
  input:-moz-autofill-preview:active
  {
    -webkit-text-fill-color: $main-text-color;
    color: $main-text-color;
    caret-color: $main-text-color;
    box-shadow: $autocomplete-box-shadow;
    -webkit-box-shadow: $autocomplete-box-shadow;
    -moz-box-shadow: $autocomplete-box-shadow;
    background: $main-bg-color-8;
  }
}

@mixin toRem($property, $value) {
  #{$property}: ($value / 16) + rem;
}

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-button {
    display: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background: $scrollbar-thumn-color;
    border-radius: 3px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumn-hover-color;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background: $scrollbar-thumn-active-color;
  }
  
  ::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
    border-radius: 2px;
  }
  
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}