@import "src/assets/styles/colors";

.np-workers-count {
  gap: 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: $primary;
      text-decoration: none;

      &:hover {
        color: $maximum-blue;
      }
    }
  }
}