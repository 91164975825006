@import "src/assets/styles";

$landing-max-width: 1440px;

.np-public-layout-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
    background-color: $sky-extralight;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-right {
            display: flex;
            align-items: center;
            gap: 24px;
            
            @media (max-width: 800px) {
                gap: 8px;            
            }
        }
    }

    &__select {
        @media (max-width: 600px) {
            padding: 8px 16px !important;
            
            span {
                font-size: 16px !important;
            }
        }
    }
}