@import "src/assets/styles/colors";

.np-info-block {
  display: flex;
  flex-direction: column;

  &__items {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 36px; // change to 24px
    flex-wrap: wrap;

    &:first-child {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid $light-silver-20;
    }

    &--no-border {
      &:first-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $spanish-gray;
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      font-weight: 400 !important;
    }

    > img {
      transform: rotate(-25deg);
      position: relative;
      top: 0px;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
    }
  }

  &__value {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: $main-text-color;
  }
}