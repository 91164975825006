@import "src/assets/styles";

.np-export-form {
    .ant-form-item {
        margin-bottom: 36px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}