@import "src/assets/styles";

.np-banner-carousel {
    align-self: center;
    width: 100%;
    padding: 0 16px;
    border-radius: $container-border-radius;

    &--static {
        padding: 0;
        border-radius: $container-border-radius;

        .np-banner {
            border-radius: $container-border-radius;
        }
    }
}