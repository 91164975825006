@import "src/assets/styles/index";

.np-user-menu {
    &__dropdown {
        .ant-dropdown-menu-item-icon {
            width: 12px;
            height: 12px;
        }
    } 
    
    &__button {
        font-size: 12px;
    }
}