@import "src/assets/styles";

.np-date-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &__separator {
      color: $input-border-color;
    }

    &__popup {
      @media (max-width: 992px) {
        .ant-picker-panel:last-child .ant-picker-date-panel {
          display: none !important;
        }
      
        .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-next-btn,
        .ant-picker-panel:first-child .ant-picker-date-panel .ant-picker-header-super-next-btn {
          visibility: initial !important;
        }
      
        .ant-picker-panel.ant-picker-range-wrapper {
          min-width: 288px !important;
        }
      
        .ant-picker-presets {
          display: none !important;
        }
      }
    }
    
    .ant-picker {
        border-radius: 40px;
        min-width: 150px;
        width: 150px;
        background-color: $main-bg-color-8 !important;
        border-color: $input-no-border-color;

        &:hover, &:focus, &:active, &:focus-within {
          border-color: $primary;
        }

          &.bordered {
            border-color: $input-border-color;

            &:hover, &:focus, &:active, &:focus-within {
              border-color: $primary;
            }
          }

        &-large {
          height: 40px;

          input {
            font-size: 14px;
          }
        }
      }
}