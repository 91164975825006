@import "src/assets/styles";

.np-full-screen-chart-modal {
   .ant-modal-body {
        height: calc(100% - 70px);

        .np-block-wrapper {
            height: 100%;
        }
   } 
}