@import "src/assets/styles";

.np-reset-process-confirmation-reminders {
    margin: 0;
    padding-inline-start: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
        font-size: 14px;
        line-height: 1.57;
        color: $default-desc-color;
    }

    a {
        color: $primary;
        text-decoration: none;
        font-weight: 500;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.85;
        }
    }
}