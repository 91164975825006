@import "src/assets/styles";

.np-payment-settings-info-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 16px;
    border: 1px solid $radio-border-color;
    border-radius: $container-border-radius;
    width: fit-content;

    &__header {
        display: flex;
        align-items: center;
        gap: 4px;

        &-title {
            font-size: 14px;
            line-height: 19px;
            color: $spanish-gray;

            @media (max-width: 700px) {
                font-size: 12px;
            }
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    &__body {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
            fill: $spanish-gray;

            path {
                fill: inherit;
            }
        }

        &-value {
            font-size: 24px;
            line-height: 32px;

            @media (max-width: 700px) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}