@import "src/assets/styles/index";

.np-user-layout-logo {
    margin-top: 4px;
    width: 130px;
    height: 40px;
    cursor: pointer;

    svg {
        width: 130px;
        height: 40px;
    }
}

@media (max-width: 900px) {
    .np-user-layout-logo {
        margin-top: 0;
        width: 97px;
        height: 30px;

        svg {
            width: 97px;
            height: 30px;
        }
    }
}