@import "src/assets/styles";

$bg-looper-url: url("../../../../assets/images/svg/bg-looper.svg");
$bg-looper-light-url: url("../../../../assets/images/svg/bg-looper-light.svg");
$bg-looper-light-main-url: url("../../../../assets/images/svg/bg-looper-light-main.svg");
$bg-looper-dark-url: url("../../../../assets/images/svg/bg-looper-dark.svg");
$bg-looper-dark-mail-url: url("../../../../assets/images/svg/bgDark.svg");
$bg-ellipse-left-url: url("../../../../assets/images/svg/bg-ellipse-left.svg");
$bg-ellipse-right-url: url("../../../../assets/images/svg/bg-ellipse-right.svg");

.np-background-layout {
    &--solid-light {
        background: $main-bg-color bottom right/100% no-repeat $bg-looper-light-url;
    }

    &--solid-dark {
        background: $main-bg-color bottom right/100% no-repeat $bg-looper-dark-url;
    }

    &--looper-light {
        background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 450px / 135% no-repeat $bg-looper-light-main-url;
    }

    &--looper-dark {
        background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 450px / 135% no-repeat $bg-looper-dark-mail-url;
    }

    &--gradient {
        &-light {
            background: top left/50% no-repeat $bg-ellipse-left-url,  bottom right/50% no-repeat $bg-ellipse-right-url, $main-bg-color bottom right/100% no-repeat $bg-looper-light-url;
        }

        &-dark {
            background: top left/50% no-repeat $bg-ellipse-left-url, bottom right/50% no-repeat $bg-ellipse-right-url, $main-bg-color bottom right/100% no-repeat $bg-looper-url;
        }
    }
}

@media (min-width: 400px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 350px / 135% no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 350px / 135% no-repeat $bg-looper-light-main-url;
        }
    }   
}

@media (min-width: 500px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 250px / 135% no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 250px / 135% no-repeat $bg-looper-light-main-url;
        }
    }   
}

@media (min-width: 800px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 200px / 135% no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 200px / 135% no-repeat $bg-looper-light-main-url;
        }
    }   
}

@media (min-width: 1000px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 100px / 135% no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 100px / 135% no-repeat $bg-looper-light-main-url;
        }
    }   
}

@media (min-width: 1200px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color -100px 0 / 135% no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color -100px 0 / 135% no-repeat $bg-looper-light-main-url;
        }
    }   
}

@media (min-width: 1500px) {
    .np-background-layout {
        &--looper-dark {
            background: bottom right/100% no-repeat $bg-looper-dark-url, $main-bg-color center 0 / 1900px no-repeat $bg-looper-dark-mail-url;
        }

        &--looper-light {
            background: bottom right/100% no-repeat $bg-looper-light-url, $main-bg-color center 0 / 1900px no-repeat $bg-looper-light-main-url;
        }
    }   
}