@import "src/assets/styles";

.np-enable-ga-modal {
    .ant-modal-body {
        align-items: center;
    }

    &__steps {
        @media (max-width: 575px) {  
            width: auto;
        }
    }
}