@import "src/assets/styles";

.np-reset-process-identif-verify {
    &__files {        
        &-list {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;

            .ant-image {
                border-radius: 10px;

                img {
                    object-fit: contain;
                    background-color: $main-bg-color;
                    border-radius: inherit;
                }

                .ant-image-mask {
                    border-radius: inherit;
                }
            }
        }

        &-mask {
            .np-action-icon-button {
                width: 32px !important;
                height: 32px !important;

                svg {
                    font-size: 20px;
                }
            }
        }
    }
}