@import "src/assets/styles";

.np-table {
  // min-height: 160px;

  // .ant-table-body {
  //   min-height: 160px;
  // }

  &td {
    cursor: pointer;

    &:hover {
      background: $blue-bg-color !important;

      & td.ant-table-cell-row-hover {
        background: $blue-bg-color !important;
      }
    }
  }

  &-cell {
    &--copiable:is(td) {
        cursor: pointer;
  
        &:hover {
          background: $blue-light-bg-color !important;
  
          & td.ant-table-cell-row-hover {
            background: $blue-light-bg-color !important;
          }
        }

    }

    &__tooltip {
      background: $main-bg-color;
      border-radius: 6px;

      .ant-tooltip-inner {
        font-weight: 500;
      }

      .ant-tooltip-arrow {
        &::after {
          background: $primary-70;
        }

        &::before {
          background: $main-bg-color;
        }
      }
    }
  }

  .ant-table-column-sorter {
    display: none;
  }

    &.ant-table-wrapper .ant-table {
      background: transparent;
      padding-bottom: 16px;
  
      .ant-table-container {
        table {
          border: 0;
          border-radius: 0;
  
          thead {
            > tr:first-child {
              th.ant-table-cell, td.ant-table-cell {
                &:first-child {
                  padding-left: 24px;
                }
  
                &:last-child {
                  padding-right: 24px;
                }
              }
            }

            th.ant-table-cell, td.ant-table-cell {
              border-radius: 0;
              border-start-start-radius: 0;
              border-start-end-radius: 0;
              background-color: $table-header-color;
              font-weight: 500;
              font-size: 12px;
              border-bottom: 0;
              padding: 8px;
              
              @media (max-width: 700px) {
                padding: 4px;
                font-size: 10px;
              }
            }

            .ant-table-selection-column {
              padding-left: 8px !important;
            }
          }
  
          tbody {
            & > tr {
            background: $main-bg-color-2;

              &:nth-child(odd) {
                  background: $table-row-color;

                  td.ant-table-cell-fix-right, td.ant-table-cell-fix-left {
                    background: $table-row-color;
        
                    &.ant-table-cell-row-hover {
                      background: $main-bg-color;
                    }
                  }
              }

              &.ant-table-row-selected {
                background: $table-selected-row-color;
                filter: grayscale(40%);

                & > td {
                    background: inherit !important;
                }

                &:hover {
                    background: $select-overlay-color;
                    transition: all 0.15s;
                }
            }
            }

            tr:not(.ant-table-row-selected) {
              td.ant-table-cell-row-hover {  
                &.ant-table-cell-fix-right, 
                &.ant-table-cell-fix-left {
                  background: $table-row-hover-color !important;
                }
              }
            }

            td.ant-table-cell {
              font-family: $secondFont;
              border-color: $chart-divide-line-color;
              font-size: 14px;

              &.ant-table-selection-column {
                padding-left: 8px !important;
              }

              &.font-mono {
                font-family: $monoFont !important;

                span {
                  font-family: $monoFont !important;
                }
              }
  
              & > span {
                font-size: 14px;
              }
  
              padding: 5px;
              border: none;
              
              @media (max-width: 700px) {
                padding: 2px;
                font-size: 12px;
  
                span {
                  font-size: 12px;
                }
              }

              &-row-hover {
                background: $table-row-hover-color;
                
                &.ant-table-column-sort {
                  background: $table-row-hover-color;
                }
              }

              &:first-child {
                padding-left: 24px;
              }

              &:last-child {
                padding-right: 24px;
              }
            }

            td.ant-table-column-sort {
              background: inherit;
            }
  
            td.ant-table-cell-fix-right, td.ant-table-cell-fix-left {
              background: $main-bg-color-2;
  
              &.ant-table-cell-row-hover {
                background: $main-bg-color;
              }
            }
          }
  
          .ant-table-placeholder {
            background: transparent;
          }
        }
      }
    }
  
   &--background {
     &.ant-table-wrapper .ant-table {
      border-radius: 10px;
       background: $main-bg-color-2;
  
       .ant-table-tbody >tr >td {
          border-bottom: none !important;
       }
  
       .ant-table-container {
         table {
           border: 0;
           border-radius: 10px;
  
           th.ant-table-cell {
             border-radius: 0;
             border-start-start-radius: 0;
             border-start-end-radius: 0;
  
             &:first-child {
              border-start-start-radius: 10px;
             }
  
             &:last-child {
              border-start-end-radius: 10px;
             }
           }
  
           .ant-table-placeholder {
             background: $main-bg-color-2;
           }
         }
       }
     }
   }

   .ant-pagination {
    margin: 0 24px 16px !important;
    align-items: center;

    &-options {
      margin: 0;
      margin-right: 16px;
      flex: 1;

      .ant-select-arrow {
        font-size: 14px;

        @media (max-width: 700px) {
          font-size: 12px !important;
        }
      }

      .ant-select-selector {
        height: 40px;
        border-radius: 40px;
        border-color: $input-border-color !important;
        background-color: $main-bg-color-8 !important;

        @media (max-width: 700px) {
          height: 30px;
        }

        .ant-select-selection-item {
          line-height: 38px;
          padding-inline-end: 20px;

          @media (max-width: 700px) {
            line-height: 30px;
          }
        }
      }
  
      &:hover, &:focus, &:active, &:focus-within {
        .ant-select-selector {
          border-color: $primary !important;
        }
      }

      @media only screen and (max-width: 576px) {
        display: block;
      }
    }
  }

   .ant-table-empty {
      table {
        height: 100%;
      }
    }

    .ant-checkbox-wrapper-disabled {
      opacity: 0.4;
    }

    //no pagination

    &--no-pagination {
      &.ant-table-wrapper .ant-table {
        padding-bottom: 0 !important;
      }
    }

    //stretch

    &--stretch {
      &.ant-table-wrapper {
        height: 100%;
      
        .ant-spin-nested-loading {
          height: 100%;
      
          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
      
            .ant-table {
              flex: auto;
              overflow: hidden;

              .ant-table-content {
                height: 100%;
              }
      
              .ant-table-container {
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
      
                .ant-table-header {
                  flex: none;
                }
      
                .ant-table-body {
                  flex: auto;
                  overflow: scroll;
                  max-height: 100%;
                }
              }
            }
      
            .ant-table-pagination {
              flex: none;
            }
          }
        }
      }
    }

    // empty

    .ant-table-empty {
      table {
        tbody {
          td.ant-table-cell {
            &:first-child {
              padding-left: 8px !important;
            }

            &:last-child {
              padding-right: 8px !important;
            }
          }
        }
      }
    }
  }