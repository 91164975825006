@import "src/assets/styles/index";

.np-accordion {
  &--default {
    background-color: $main-bg-color-2;

    .ant-collapse-item {
      border-radius: 10px !important;

      .ant-collapse-header {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        padding: 20px 24px;
        background-color: $main-bg-color-2;
        border-radius: inherit !important;

        @media (max-width: 900px) {
          font-size: 14px;
          line-height: 26px;
        }

        .ant-collapse-header-text {
          @media only screen and (max-width: $laptop) {
              text-align: start;
            }
        }

        .ant-collapse-expand-icon {
          padding-top: 9px;

          @media (max-width: 900px) {
            padding-top: 0px;
          }

          svg {
            height: 20px;
            width: 20px;
            fill: $primary;
          }
        }
      }

      &.np-small {
        .ant-collapse-header {
          font-size: 14px;
          font-weight: 400;

          .ant-collapse-expand-icon {
            padding-top: 0;
          }
        }
      }

      .ant-collapse-content-box {
          @media only screen and (max-width: $laptop) {
            text-align: start;
          }
    
          @media (max-width: 900px) {
            font-size: 14px;
          }
        }
    }

    &.np-divided {
      background-color: $main-bg-color;

      .ant-collapse-content {
        margin-top: 10px;
    }

      .ant-collapse-content-box {
          padding-top: 24px !important;
          background-color: $main-bg-color-2;
          border-radius: 10px;

          & > div {
              flex: 1;
          }
      }
    }
  }

    &--ghost {
      background-color: transparent;

      .ant-collapse-item {
        border-radius: 0 !important;
  
        .ant-collapse-header {
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 120%;
          padding: 0 !important;
          background-color: transparent;

          @media (max-width: 1440px) {
            font-size: 24px;
          }

          @media (max-width: 500px) {
            font-size: 20px;
          }
  
          .ant-collapse-expand-icon {
            padding-top: 0;
  
            svg {
              height: 16px;
              width: 16px;
            }
          }
        }
  
        .ant-collapse-content-box {
            font-size: 18px;
            line-height: 130%;
            padding: 0 !important;
            padding-top: 16px !important;
            color: $spanish-gray;
            
            @media (max-width: 1440px) {
              font-size: 16px;
            }

            @media (max-width: 500px) {
              font-size: 14px;
            }
          }
      }
    }
}