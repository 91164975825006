@import "./colors";
@import "./fonts";
@import "../fonts/Satoshi/css/satoshi.css";
@import "../fonts/Roboto/roboto.css";
@import "src/assets/styles/index";

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Fira Mono";
  src: url("../fonts/Fira_Mono/FiraMono-Regular.ttf");
}

@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito/Nunito-VariableFont_wght.ttf");
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SF_Pro/SF-Pro.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $secondFont;

  scrollbar-color: $scrollbar-thumn-color $scrollbar-track-color !important;
  scrollbar-width: thin !important;
}

body {
  min-height: 100vh;
  background-color: $main-bg-color;
  color: $main-text-color;
  user-select: none;
}

ul, ol {
  padding-inline-start: 20px;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Common

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

@include scrollbar;
@include input-autocomplete;