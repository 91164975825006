@import "src/assets/styles";

.np-inline-info-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;

    &--no-padding {
        padding: 0;
        padding-bottom: 24px;

        @media (max-width: 700px) {
            padding-bottom: 17px;
        }
    }

    &__title {
        max-width: none;
        width: fit-content;
        font-weight: 22px;
        font-weight: 500;

        @media (max-width: 700px) {
            font-size: 12px;
        }
    }

    &__tooltip-icon {
        cursor: pointer;
        flex: none;
        
        path {
          fill: $default-desc-color-2;
        }
    }

    &__rows {
        display: grid;
        grid-template-columns: minmax(auto, 250px) minmax(100px, 1fr) auto;
        background: $table-row-color;

        @media (max-width: 700px) {
            grid-template-columns: auto minmax(100px, 1fr) auto;
        }

        &>div:nth-child(6n+1),
        &>div:nth-child(6n+2),
        &>div:nth-child(6n+3)
        {
            background: $main-bg-color-2;
        }

        &>div:nth-child(3n)
        {
            padding: 8px 24px;

            @media (max-width: 700px) {
                padding: 8px 17px;
            }
        }

        &>div:nth-child(3n-1),
        &>form:nth-child(3n-1)
        {
            height: 100%;
            padding: 12px 0;
        }

        &-title {
            font-size: 14px;
            color: $spanish-gray;
            vertical-align: super;

            @media (max-width: 700px) {
                font-size: 12px;
            }

            margin-right: 4px;

            &-wrapper {
                padding: 12px 24px;
                gap: 4px;

                @media (max-width: 700px) {
                    padding: 12px 17px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 2px !important;

                    @media (max-width: 700px) {
                        width: 14px;
                        height: 14px;
                    }
                }

                &-block {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}