@import "src/assets/styles";

.np-balance-tooltip-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    column-gap: 16px;
    row-gap: 5px;

    .ant-typography {
        color: $white;
        white-space: nowrap;
    }
}