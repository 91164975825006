@import "src/assets/styles";

.np-captcha-error-alert {
    margin-bottom: 10px;

    .ant-alert-message {
        margin-bottom: 0;
    }

    .ant-alert-action {
        height: 17px;
        margin-top: 2px;
        cursor: pointer;
        transition: all 0.2s;

        path {
            stroke: $main-text-color;
        }
    }

    &--open {
        .ant-alert-description {
            margin-top: 8px;
        }

        .ant-alert-action {
            transform: rotate(180deg);
        }
    }
}