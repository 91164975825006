@import "src/assets/styles";

.np-form-buttons {
    margin-top: 36px;
    width: 100%;

    & > div {
        flex: 1;
    }

    @media (max-width: 500px) {
        flex-direction: column-reverse !important;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}