@import "src/assets/styles";

.np-action-icon-button {
    min-width: 16px !important;
    width: 16px !important;
    height: 16px !important;
    background-color: transparent !important;

    &:hover:not(:disabled) {
        opacity: 0.7;
    }

    &:disabled {
        color: $spanish-gray !important;
        opacity: 0.7;

        svg {
            fill: $spanish-gray !important;
            opacity: 0.7;
        }
    }

    &--primary {
        color: $primary !important;

        svg {
            fill: $primary !important;  
        }

        &:hover {
            color: $primary !important; 
        }
    }

    &--red {
        color: $error-red !important;

        svg {
            fill: $error-red !important;  
        }

        &:hover {
            color: $error-red !important; 
        }
    }

    &--gray {
        color: $light-silver !important;

        svg {
            fill: $light-silver !important;  
        }

        &:hover {
            color: $light-silver !important; 
        }
    }

    span {
        font-size: 12px;
        vertical-align: text-top;
    }
  }