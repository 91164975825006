@import "src/assets/styles";

.np-notice-modal {
    .np-base-modal-title {
        color: $primary;
    }

    .ant-modal-close-x {
        svg {
            fill: $primary;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-btn {
            min-height: 40px;
            height: auto;
    
            span {
                white-space: initial;
            }

            &-default {
                border-color: $primary !important;
                color: $primary !important;
                transition: opacity 0.15s;

                &:hover {
                    opacity: 0.8
                }
            }
        }
    }

    .ant-typography {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
}