@import "src/assets/styles";

.np-enable-ga-security-check {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &__form {
        width: 100%;

        @include input-autocomplete;
    }

    &__text {
        align-self: baseline;
        font-weight: 400;
    }

    &__key {
        font-size: 14px;
        font-weight: 500;
    }

    &__code-wrapper {
        width: 100%;

        .ant-space-item:first-child {
            width: 100%;

            .np-list-block__body {
                text-align: center;
            }
        }
    }
}