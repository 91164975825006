@import "src/assets/styles";

.np-security-notice-modal {
    .np-base-modal-title {
        color: $primary;
    }

    .ant-modal-close-x {
        svg {
            fill: $primary;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-btn {
            min-height: 40px;
            height: auto;
    
            span {
                white-space: initial;
            }
        }
    }

    .ant-typography {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
}