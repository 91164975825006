@import "src/assets/styles";

.np-select {
    &.ant-select {
        .ant-select-selector {
            background-color: $main-bg-color-8 !important;
            border-radius: 40px;
          }
        
          input {
            font-size: 16px;
            font-family: $secondFont;
          }
        
          &:hover, &:focus, &:active, &:focus-within {
            &:not(:disabled) {     
                .ant-select-selector {
                  border-color: $primary !important;
                }
            }
          }
        
          &-lg {
            .ant-select-selector {
              border-radius: 40px !important;
            }
        
            input {
              font-size: 14px !important;
            }
        
            .ant-select-selection-item {
              font-size: 14px !important;
            }
          }
        
          &-sm {
            .ant-select-selector {
              height: 32px !important;
              border-radius: 40px !important;
              padding: 0 11px !important;
              align-items: center;
            }
        
            input {
              font-size: 14px !important;
              height: 32px !important;
            }
        
            .ant-select-selection-item {
              font-size: 14px !important;
              line-height: 30px;
            }
        
            .ant-select-selection-placeholder {
              line-height: 30px;
            }
          }
    }

    &__filled {
        .ant-select-selector {
            border-color: $input-no-border-color !important;
          }  

          &:disabled {
            .ant-select-selector {
              border-color: $input-no-border-color !important;
            }
          }
    }

    &__bordered {
        .ant-select-selector {
            border-color: $input-border-color !important;
          }
      
          &:disabled {
            .ant-select-selector {
              border-color: $input-border-color !important;
            }
          }
    }
}