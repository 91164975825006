@import "src/assets/styles/index";

.np-user-menu-sub-account {
    &__auto-transf {
        &-tooltip {
            &-title {
                color: $spanish-gray;
                font-weight: 500;
            }
        }

        &-accounts {
            padding-inline-start: 20px;
        }
    }
}