@import "src/assets/styles";

.np-activation-switcher {
    display: flex;
    align-items: center;
    gap: 6px;
    
    &__tooltip-icon {
        cursor: pointer;
        
        path {
          fill: $default-desc-color-2;
        }
    }
}