@import "src/assets/styles";

.np-settings-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    display: flex;
    gap: 22px;
    min-height: 32px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    gap: 15px;
  }

  &-body {
    &__row {
      display: flex;
      width: 100%;
      padding: 12px 0;
      border-bottom: 1px solid rgba($light-silver, 0.4);

      &:last-child {
        border-bottom: 0;
      }

      &--no-padding {
        padding: 0 !important;
      }
    }

    &__title {
      color: $spanish-gray;
      max-width: 170px;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
    }

    &__value {
      font-weight: 500;
      font-size: 16px;
      color: $main-text-color;
      align-self: center;

      &--full-width {
        width: 100%;
      }
    }
  }
}