@import "src/assets/styles";

.np-enable-2fa-comleted-step {
    width: 100%;
    text-align: center;

    &__title {
      font-weight: 500;
    }

    &__list-block {
        margin: 8px 0 36px;
    }

    &__codes {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
        list-style-type: none;
        user-select: text;
        justify-items: center;
        justify-content: center;
        margin: 0;
        gap: 6px;
  
        > .ant-typography {
          font-size: 16px;
          font-weight: 500;
        }
    }
  }