@import "src/assets/styles";

.np-user-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  
    &__body {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-content {
        display: flex;
        flex: 1;
      }
    }
}