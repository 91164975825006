@import "src/assets/styles/index";

.np-password-validation-input {
  &__rules {
    display: flex;
    flex-direction: column;

    > span {
      font-weight: 400;
    }
    
    > span[data-type="success"] {
      color: #52C41A;
    }
  
    > span[data-type="default"] {
      color: #949495;
    }
  }
}

