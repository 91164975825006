@import "src/assets/styles";

.np-switch-item {
    width: 100%;
    max-width: 610px;

    @media (max-width: 900px) {
        max-width: none;
    }

    &__content {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
            fill: $default-desc-color-2;
            cursor: pointer;

            path {
                fill: inherit;   
            }
        }
    }

    &__loading {
        min-width: 24px;
    }

    .ant-divider-horizontal {
        margin: 20px 0 44px 0;

        @media (max-width: 700px) {
            margin: 12px 0; 
        }
    }

    .ant-typography {
        font-size: 14px;
        font-weight: 400;
    }
}