@import "src/assets/styles";

.np-forgot-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 500px) {
        flex-direction: column;
    }

    a {
        font-weight: 500;
        text-align: center;
    }

    .ant-btn-link {
        padding: 0;
        height: auto;

        span {
            white-space: normal;
        }
    }

    .ant-typography {
        color: $default-desc-color-2;

        @media (max-width: 500px) {
            display: none;
        }
    }
}