@import "src/assets/styles";

.np-enable-ga-download-app {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-top: 16px;

    &__title {
        text-align: center;
        font-weight: 400;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
        margin-bottom: 28px;

        & > a {
            flex: 1;
        }
    }
}