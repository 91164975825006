@import "src/assets/styles";

.np-reset-process {
    &__step-layout {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
    
        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
    
            .ant-typography {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
            }
    
            svg {
                width: 18px;
                height: 18px;
            }
        }

        &-desc {
            color: $default-desc-color-2;
            text-align: center;
        }
    }
}