@import "src/assets/styles";

.np-checkbox {
    &:not(.ant-checkbox-wrapper-disabled) {
      .ant-checkbox-inner {
        border-color: $checkbox-border-color;
        background-color: $main-bg-color-2;
      }
  
      &:hover {
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $main-bg-color-2 !important;
            animation: none !important;
            border: 1px solid $primary !important;
          }
  
          &::after {
            animation: none !important;
            transition: all 0.3s;
            border: none;
          }
        }
      }
  
      .ant-checkbox-checked {
        &::after {
          animation: none !important;
          transition: all 0.15s;
          border: none;
        }
  
        .ant-checkbox-inner {
          background-color: $main-bg-color-2;
          border-color: $checkbox-border-color;
    
          &::after {
            border-color: $primary;
            animation: none !important;
            transition: all 0.15s ease-in-out;
          }
        }
      }
    }
  
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: $spanish-gray;
  
        &::after {
          border-color: $spanish-gray;
        }
      }
  
      span {
          color: $spanish-gray;
      }
    }
  }