@import "src/assets/styles";

.np-payment-block-modal {
    .np-base-modal-title {
        color: $error-border-color;
    }

    &__list {
        padding-inline-start: 20px;
        margin: 0;
    }
}