@import "src/assets/styles";

.np-checkbox-list {
    background-color: $main-bg-color-8;
    border: 1px solid $radio-border-color;
    border-radius: 16px;

    &__header {
        padding: 12px 16px;
        border-bottom: 1px solid $radio-border-color;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
    }

    &__body {
        padding: 12px 16px;
        overflow: auto;

        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 10px;
        } 
    }

    &--error {
        border-color: $error-border-color;
    }
}