@import "src/assets/styles/index";

.np-steps {
    .ant-steps-item-finish {
      .ant-steps-item-icon {
          background-color: $primary !important;
      }
    
      .ant-steps-finish-icon {
          svg {
              fill: $main-bg-color;
          }
      }
    }
    
    .ant-steps-item-wait {
      .ant-steps-item-icon {
          background-color: transparent !important;
          border: 1px solid $default-desc-color !important;
    
          span {
              color: $default-desc-color !important;
              font-family: $secondFont;
              font-weight: 400;
          }
      }
    }
    
    .ant-steps-item-active {
      .ant-steps-item-title {
          color: $main-text-color !important;
      }
    }
    
    &.ant-steps-label-vertical {
      .ant-steps-item-title {
        line-height: 16px !important;
      }
    }
    
    .ant-steps-item-title {
      color: $default-desc-color !important;
      font-size: 14px !important;
      font-weight: 400;
      font-family: $secondFont;
    
      &::after {
          background-color: $main-text-color !important;
          height: 2px;
      }
    }
    
    .ant-steps-item-tail {
      &::after {
        background-color: $main-text-color !important;
      }
    }
  
    @media (max-width: 575px) {
      width: fit-content;
  
      .ant-steps-item {
        width: inherit;
  
        .ant-steps-item-container {
          width: inherit;
          display: flex;
    
          .ant-steps-item-content {
              width: inherit;
            }
        }
      }
    }
  }