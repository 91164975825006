@import "src/assets/styles";

$social-media-button__large: 34px;
$social-media-button-icon__large: 18px;

$social-media-button__medium: 27px;
$social-media-button-icon__medium: 13px;

.np-social-media-button {
    &--medium {
        min-width: $social-media-button__medium !important;
        width: $social-media-button__medium !important;
        height: $social-media-button__medium !important;

        .ant-btn-icon {  
            svg {
                width: $social-media-button-icon__medium;
                height: $social-media-button-icon__medium;
            }
        }
    }

    &--large {
        min-width: $social-media-button__large !important;
        width: $social-media-button__large !important;
        height: $social-media-button__large !important;

        .ant-btn-icon {  
            svg {
                width: $social-media-button-icon__large;
                height: $social-media-button-icon__large;
            }
        }
    }

    &--primary {
        background-color: $primary;
    }

    &--dark-blue {
        background-color: $crayola-blue;
    }

    &--text-default {
        .ant-btn-icon {
            svg {
                stroke: $main-icon-color;
                fill: $main-icon-color;
                path {
                    stroke: $main-icon-color;
                    fill: $main-icon-color;
                }
            } 
        }
    }

    &--text-white {
        .ant-btn-icon {
            svg {
                stroke: $white;
                fill: $white;
                path {
                    stroke: $white;
                    fill: $white;
                }
            } 
        }
    }

    .ant-btn-icon {
        color: $white;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // type

    &--filled {
        width: 24px !important;
        height: 24px !important;
        min-width: 24px !important;
        padding: 0;
        background-color: transparent !important;

        .ant-btn-icon {
            svg {
                width: 22px !important;
                height: 22px !important;

                path:first-of-type {
                    fill: $main-bg-color-12;
                }

                &.full-fill {
                    background-color: white;
                    border-radius: 50%;

                    path {
                        fill: $main-bg-color-12;
                    }
                }
            } 
        }
    }
}