@import "src/assets/styles";

.np-authentication-modal {
    &__link {
        font-weight: 500;
    }

    .ant-modal-body {
        align-items: center;
        gap: 10px;
    }
}