@import "src/assets/styles/index";

.np-user-menu-sub-account {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;
    border-radius: 8px;
    background-color: $menu-item-bg-color;
    outline: 1px solid $menu-item-bg-color;
    transition: all 0.2s ease;

    &:hover {
        outline: 1px solid $menu-item-border-color;

        &.selected {
            outline: 1px solid $primary-30;  
        }
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 10px;

        &-title {
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap;
        }

        &-desc {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &__hashrate {
        display: flex;
        align-items: center;
        gap: 6px;

        &-circle {
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background-color: $spanish-gray;
        }

        &-text {
            font-size: 16px;
            font-weight: 400;
            white-space: nowrap;
        }
    }

    &__workers {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;

        &-title {
            font-size: 12px;
            font-weight: 400;
        }

        &-body {
            display: flex;
            align-items: center;
            gap: 10px;

            &-item {
                display: flex;
                align-items: center;
                gap: 4px;

                .anticon {
                    font-size: 12px;
                }

                .ant-typography {
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 400;
                    white-space: nowrap;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 8px;

        &-block {
            display: flex;
            align-items: center;
            gap: 6px;
            width: fit-content;
    
            &-title {
                font-size: 12px;
                font-weight: 400;
            }
    
            &-icon {
                font-size: 16px;
                color: $spanish-gray;
            }
    
            &.active {
                .np-user-menu-sub-account__info-block-icon, 
                .np-user-menu-sub-account__info-block-title {
                    color: $primary;
                }
            }
        }
    }

    
    &.selected {
        background-color: $primary-10;
        
        .np-user-menu-sub-account {
            &__header {
                &-title {
                    color: $primary;
                }

                &-icon {
                    font-size: 16px;

                    svg {
                        fill: $primary !important;
                    }
                }
            }
        }
    }
}