@import "src/assets/styles";

.np-referral-info-block {
    display: flex;
    flex-direction: column;
    gap: 6px;

    @media (max-width: 700px) {
        &:not(:last-child) {
            padding-bottom: 16px;
            border-bottom: 1px solid $divider-color;
        }
    }

    &-text {
        font-size: 24px;
        font-weight: 500;

        @media (max-width: 700px) {
            font-size: 18px;
        }
    }

    
    &-title {
        color: $default-desc-color-4;

        @media (max-width: 700px) {
            font-size: 12px;
        }
    }
}