@import "src/assets/styles/index";

.np-vip-button {
    background: $gradient-gold-2 transparent var(--GRADIENT_PERCENT, 0%)/var(--GRADIENT_SCALE_PERCENT, 200%) no-repeat;
    transition: 0.35s;
    border-width: 0;

    span {
        color: $extradark-gray !important;
    }


    &:hover:not(:disabled) {
        --GRADIENT_PERCENT: 30%;
        --GRADIENT_SCALE_PERCENT: 300%;
        opacity: 1 !important;
        background-color: transparent !important;
    }

    &:disabled {
        --GRADIENT_PERCENT: 30%;
        --GRADIENT_SCALE_PERCENT: 300%;
        border: none;
        background-color: transparent !important;
    }
}