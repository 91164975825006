@import "src/assets/styles/index";

.np-summary-block {
    .np-info-block__items {
        @media (max-width: 700px) {    
            gap: 17px;
        }
    }

    .np-info-block__value {
        @media (max-width: 700px) {    
            font-size: 18px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        &-title {
            display: flex;
            align-items: center;
            gap: 4px;

            svg {
                fill: $spanish-gray;

                path {
                    fill: inherit;
                }
            }

            &--thin {
                font-weight: 400 !important;
            }
        }

        > a {
            font-weight: 500;
            font-size: 14px;
            text-align: end;
            color: $primary;
            text-decoration: none;
      
            &:hover {
              color: $maximum-blue;
            }
        }
    }

    &__link-block {
        text-decoration: none;
        transition: opacity 0.15s;

        &:hover {
            opacity: 0.8;

        }

        .np-info-block__title {
            transition: color 0.15s;
            color: $primary;
        }

        .np-info-block__value {
            transition: opacity 0.15s;
            color: $main-text-color;
        }
    }

    &--inline {
        .np-info-block {
            width: auto;
            flex-direction: row;
            gap: 36px;
            flex-wrap: wrap;

            @media (max-width: 700px) {    
                gap: 17px;
            }
        }

        .np-info-block__items {
            flex-wrap: wrap;
            width: auto;
        }

        .np-info-block__item {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}