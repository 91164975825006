@import "src/assets/styles";

.np-bordered-block {
    background-color: $main-bg-color-2;
    border-radius: $container-border-radius;
    padding: 8px 12px;
    border: 1px solid $radio-border-color;

    &__title {
        color: $spanish-gray;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
    }

    &__body {
        gap: 24px;
        display: flex;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &-title {
            color: $spanish-gray;
            font-size: 12px;
            line-height: 24px;
            font-weight: 400;
        }

        &-value {
            color: $main-text-color;
            font-size: 12px;
            line-height: 24px;
            font-weight: 500;
        }
    }
}