@import "src/assets/styles";

.np-reset-process {
    gap: 24px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }
}